<template>
	<div>
		<v-container>
			<v-card>
				<!-- <v-row align="center" justify="center" class="pt-2">
					<v-btn
						color="success"
						rounded
						@click="dialogJoin = true"
					>
						<i class="fas fa-pallet" style="font-size: 18px; margin-right: 6px"></i>
						Crear Verde Proceso
					</v-btn>
				</v-row> -->
				<v-row class="ml-1 mr-1">
					<v-col cols="12" lg="12" md="12" sm="12">
						<s-crud
							title="Pallets Abastecidos"
							:config="config"
							@rowSelected="rowSelected($event)"
							:filter="filter"
							ref="dataProcess"
							height="auto"
						>
							<template v-slot:filter>
								<v-container>
									<v-row justify="center" class="s-col-form">
										<v-col cols="12" lg="4" md="4">
											<s-select-definition
												v-model="filter.FprTypeProcess"
												label="Proceso"
												:def="1340"
											>
											</s-select-definition>
										</v-col>
										<v-col cols="12" lg="4" md="4">
											<s-date
												label="Fecha Inicio"
												v-model="filter.BeginDate"
											>
											</s-date>
										</v-col>
										<v-col cols="12" lg="4" md="4">
											<s-date label="Fecha Fin" 
												v-model="filter.endDate">
											</s-date>
										</v-col>
									</v-row>
								</v-container>
							</template>
						</s-crud>
					</v-col>
					<v-col cols="12" lg="12" md="12" sm="12">
						<v-row>
							<v-col>
								<s-toolbar
									label="Registro de Saldos"
									dark 
									color="#bbbcbd"
									save
									add
									@save="save()"
									@add="addDiscard()"
								></s-toolbar>
							</v-col>
						</v-row>
						<v-container>
							<v-row>
								<v-col
									:cols="isGreenProcess == true ? 12 : 12"
									:lg="isGreenProcess==true ? 4: 6"
									:md="isGreenProcess==true ? 4: 6"
									class="pl-0 pb-0"
								>
									<s-select-definition
										label="Tipo de saldo"
										ref="refTypeDiscard"
										return-object
										@input="inputDiscard($event)"
										:def="1344"
									>
									</s-select-definition>
								</v-col>
								<v-col
									:cols="isGreenProcess == true ? 12 : 12"
									:lg="isGreenProcess==true ? 4: 6"
									:md="isGreenProcess==true ? 4: 6"
									class="pl-0 pb-0"
								>
									<s-text
										v-model="DrpDiscardWeight"
										decimal
										ref="refDiscardWeight"
										label="Peso de descarte"
									>
									</s-text>
								</v-col>
								<v-col
									v-if="isGreenProcess"
									:cols="isGreenProcess == true ? 12 : 12"
									:lg="isGreenProcess==true ? 4: 6"
									:md="isGreenProcess==true ? 4: 6"
									class="pl-0 pb-0"
								>
									<s-text 
										number 
										ref="refAmountJaba" 
										v-model="DrpAmountJaba" 
										label="Cant. Jabas"
									>
									</s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-data-table
										:headers="headerlDiscard"
										:items="itemsDataDiscard"
										hide-default-footer
										:items-per-page="-1"
										dense
										disable-sort
									>
										<template v-slot:item.Actions="{ item }">
											<v-btn
												@click="removeItemDiscard(item)"
												x-small
												block
												rounded
												color="error"
											>
												<v-icon style="font-size: 16px !important"
												>mdi-close</v-icon
												>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-card>
		</v-container>

		<v-dialog
			v-model="dialogJoin"
			persistent
			width="800"
		>
			<join-green-process
				@closeDialogJoin="closeDialogJoin()"
			></join-green-process>
		</v-dialog>
		
	</div>
</template>

<script>
	import _sProcessRegistrationService from "@/services/FrozenProduction/ProcessRegistrationService";
	import JoinGreenProcess from "./JoinGreenProcess.vue"

	export default {
		components: {
			JoinGreenProcess,
		},

		data() {
			return {
				filter: {},
				config: {
					service: _sProcessRegistrationService,
					model: {
						FprID: "ID",
						FprDate:"date",
						edited : "",
					},
					headers: [
						{ text: "ID", value: "FprID", width: "5%", align: "begin", sorteable: true},
						{ text: "Pallet Procesado", value: "PprID", width: "5%", align: "begin", sorteable: true},
						{ text: "Fecha", value: "FprDate", width: "10%", sorteable: true },
						{ text: "Tipo de proceso", value: "TypeProcessName", width: "15%", sorteable: false },
						/* { text: "Hora Inicio", value: "FprHourBegin", width: "10%", sorteable: true },
						{ text: "Hora Fin", value: "FprHourEnd", width: "10%", sorteable: true },
						{ text: "Materia P Movida", value: "FprMovedRawMaterial", width: "10%", sorteable: true },
						{ text: "Materia P Movida Acu", value: "FprMovedRawMaterialAcu", width: "10%", sorteable: true },
						{ text: "Materia P Procesada", value: "FprProcessRawMaterial", width: "10%", sorteable: true },
						{ text: "Materia P Procesada Acu", value: "FprProcessRawMaterialAcu", width: "10%", sorteable: true }, */
						/* { text: "Variedad", value: "VrtName", width: "5%", align: "center", sorteable: false },
						{ text: "Cultivo", value: "TypeCropName", width: "5%", align: "center", sorteable: false },
						{ text: "Tipo cultivo", value: "TypeCultiveName", width: "5%", align: "center", sorteable: false }, */
					],
				},
				isGreenProcess: false,
				DrpDiscardWeight: 0,
				DrpAmountJaba: 0,
				headerlDiscard: [
					{ text: "Acciones", value: "Actions", width: "100", align: "begin", sorteable: true},
					{ text: "Tipo Descarte", value: "TypeDiscardName", width: "100", align: "begin", sorteable: true},
					{ text: "Peso Descarte", value: "DrpDiscardWeight", width: "100", align: "begin", sorteable: true},
					{ text: "Cant. Jabas", value: "DrpAmountJaba", width: "100", align: "begin", sorteable: true},
				],
				itemsDataDiscard: [],
				itemsDataProccess: {},
				itemsDetailPallet: [],
				itemsDeleteDiscard: [],
				isEdit: false,
				dialogJoin: false,
			}
		},

		methods: {
			closeDialogJoin()
			{
				this.dialogJoin = false;
			},

			inputDiscard(item) {
				let ProcessGreen = 0;
				this.TypeDiscard = item ? item.DedValue : "";
				this.TypeDiscardName = item ? item.DedDescription : "";
				ProcessGreen = item ? item.DedValue : 0;

				if (ProcessGreen == 1) {
					this.isGreenProcess = true;
				} else {
					this.isGreenProcess = false;
				}
			},

			rowSelected(rows) {
				if(rows.length > 0){
					this.itemsDataProccess = rows[0];
					_sProcessRegistrationService
					.getDetails(this.itemsDataProccess, this.$fun.getUserID())
					.then(resp => {
						this.itemsDataProccess = resp.data;
                        console.log("🚀 ~ file: GreenProcess.vue ~ line 207 ~ rowSelected ~ this.itemsDataProccess", this.itemsDataProccess)
                        this.itemsDataDiscard = resp.data.DataDiscardReceptionProcess;
						if(resp.data.DataDiscardReceptionProcess.length > 0){
							this.isEdit = true;
						}
						else
						{
							this.isEdit = false;
						}
						this.itemsDetailPallet = resp.data.DataProcessRegistrationDetail
					})
				}
				
			},

			addDiscard() {
				if(this.itemsDataProccess.FprID == undefined){
					this.$fun.alert("No hay pallets seleccionado", "warning");
					return;
				}

				if(this.itemsDataDiscard.find(x=>x.TypeDiscard == this.TypeDiscard)){
					this.$fun.alert("Selecciona otro tipo de saldo", "warning");
					this.$refs.refTypeDiscard.focus();
					return;
				}

				if(this.isGreenProcess && this.DrpAmountJaba <= 0 ) {
					this.$refs.refAmountJaba.error('Debe ingresar cantidad de cajas');
				} else {

					if (this.DrpDiscardWeight > 0) {

					let itemsDiscard = {};
					itemsDiscard.FprID = this.itemsDataProccess.FprID;
					itemsDiscard.TypeDiscard = this.TypeDiscard;
					itemsDiscard.DrpAmountJaba = this.DrpAmountJaba;
					itemsDiscard.TypeDiscardName = this.TypeDiscardName;
					itemsDiscard.Line =this.itemsDataDiscard.length + 1;
					itemsDiscard.SecStatus = 1;
					itemsDiscard.UsrCreateID = this.$fun.getUserID();
					itemsDiscard.UsrUpdateID = this.$fun.getUserID();

					itemsDiscard.DrpDiscardWeight = this.DrpDiscardWeight;
					console.log('Line', itemsDiscard)
					this.itemsDataDiscard.push(itemsDiscard);
					// this.calculateWeights();

					console.log('datos agregados en detalles', this.itemsDataDiscard)

					this.itemsDataProccess.FprProcessRawMaterial = this.totalWeight - this.totalDiscard; 
					
					itemsDiscard = {};
					this.DrpAmountJaba = 0;
					this.DrpDiscardWeight = 0;

					} else {
					this.$refs.refDiscardWeight.error('Debe ingresar un peso válido');
					}
					
				}
			},

			removeItemDiscard (item) {
				this.itemsDataDiscard = this.itemsDataDiscard.filter(
					(x)=> x.TypeDiscard != item.TypeDiscard
				);

				item.SecStatus = 0;
				if(this.isEdit) this.itemsDeleteDiscard.push(item);

				this.calculateWeights();
			},

			calculateWeights (){

				// CALCULAMOS EL PESO DE TODOS LOS PALLET
				this.totalWeight = 0;
				this.itemsDetailPallet.forEach((e)=>{
					this.totalWeight += e.FpdWeightPallet;
				})

				// CALCULAMOS EL TOTAL DEL PESO DEL DESCARTE
				this.totalDiscard = 0;
				this.itemsDataDiscard.forEach((e)=>{
					this.totalDiscard += parseInt(e.DrpDiscardWeight);
				})

				this.itemsDataProccess.FprMovedRawMaterial = this.totalWeight; 
				this.itemsDataProccess.FprProcessRawMaterial = this.totalWeight - this.totalDiscard; 

			},

			save(){
				this.itemsDataProccess.DataDiscardReceptionProcess = this.itemsDataDiscard;
				this.itemsDataProccess.DataDiscardReceptionProcess.push(...this.itemsDeleteDiscard)

                this.itemsDataProccess.DataProcessRegistrationDetail = [];
                console.log("🚀 ~ file: GreenProcess.vue ~ line 297 ~ save ~ this.itemsDataProccess", this.itemsDataProccess);
				console.log("eliminados ", this.itemsDeleteDiscard);
                
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						_sProcessRegistrationService
						.save(this.itemsDataProccess, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Guardado Correctamente", "success");
								this.itemsDataProccess = {};
								this.itemsDataDiscard = [];
								this.itemsDetailPallet = [];
								this.itemsDeleteDiscard = [];
							}
						})
					}
				})
				
			}
		},
	}
</script>
