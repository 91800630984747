<template>
	<v-card>
		<v-container>
			<v-row>
				<s-toolbar
				label="Unir Saldos"
				save
				@save="save()"
				close
				@close="closeDialogJoin()"
				black
				color="#bbbcbd"
			>
			</s-toolbar>
			</v-row>
			<v-row lign="center" justify="center" class="pt-2">
				<v-btn
					fab
					color="success"
				>
					<h1>{{jabasTotal}}</h1>
				</v-btn>
			</v-row>
			<v-row>
				<v-col>
					<v-data-table
						:headers="headers"
						:items="balances"
						:single-select="singleSelect"
						show-select
						v-model="selectedBalances"
						item-key="DrpID"
					>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	import _sProcessRegistrationService from "@/services/FrozenProduction/ProcessRegistrationService";

	export default {
		data() {
			return {
				headers: [
					/* { text: "Accion", value: "accion" }, */
					{ text: "ID", value: "DrpID"},
					{ text: "Cantidad Jabas", value: "DrpAmountJaba"}
				],
				balances: [],
				selectedBalances: [],
				singleSelect: false,
				jabasTotal: 0,
				DrpIDArray: [],
			}
		},

		watch: {
			selectedBalances() 
			{
				this.jabasTotal = 0;
				this.DrpIDArray = [];
				this.selectedBalances.forEach(element => {
					this.jabasTotal = this.jabasTotal + element.DrpAmountJaba;
					this.DrpIDArray.push(element.DrpID);
				})
				console.log("🚀 ~ file: JoinGreenProcess.vue ~ line 81 ~ row", this.DrpIDArray)
			}
		},

		methods: {
			Initialize()
			{
				let obj = {
					TypeDiscard: 1
				}
				_sProcessRegistrationService
				.discarsearch(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.balances = resp.data;
					}
				})
			},

			save()
			{
				
			},

			closeDialogJoin()
			{
				this.$emit("closeDialogJoin")
			}
		},

		created () {
			this.Initialize();
		},
	}
</script>
